<template>
    <div>
        <LoaderDefault v-if="isLoading" />

        <div v-else>
            <DefaultHeader2 :routeBack="'/News'" :titleHeader="'Detalle noticia ' + dataNew.title" />
            <p class="description">{{ dataNew.description }}</p>
            <div class="wrap-box">
                <ul class="gallery">
                    <li class="image-thumbnail" v-for="(item, index) in dataNew.images" :key="index">
                        <a href="#lightbox_1" @click="actionsDom(item)">
                            <img :src="item.urlImage" alt="">
                        </a>
                    </li>                    
                </ul>
            </div>

            <div class="light-box" id="lightbox_1">
                <div class="edges"><span class="close-btn"><a href="#">X</a></span>
                    <p class="title">{{ dataAction.name }}</p>
                    <div class="inner-image">
                        <img :src="dataAction.urlImage" alt="image 01" class="swap" />
                        <span class="image-title">{{ dataAction.description }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { db, storage } from '../../../../firebaseConfig'
import LoaderDefault from '../../../components/Loaders/LoaderDefault.vue';
import DefaultHeader2 from '../../../components/Headers/DefaultHeader2.vue';

export default {
    components: {
        DefaultHeader2,
        LoaderDefault
    },
    data() {
        return {
            isLoading: false,
            dataNew: {},
            dataAction: [],
            idParam: ''
        };
    },
    mounted() {
        this.idParam = this.$route.params._id
        this.getDataNew()
    },

    methods: {
        async getDataNew() {
            this.isLoading = true
            await db.collection('news')
                .doc(this.idParam)
                .get()
                .then((res) => {
                    this.dataNew = res.data();
                    this.isLoading = false
                })
        },

        actionsDom(index) {
            this.dataAction = index
        }
    },

}
</script>

<style scoped>
.description {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    color: black;
    text-align: center;
}


.gallery {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.image-thumbnail {
    display: inline-block;
    width: auto;
    height: auto;
    background-color: #eee;
    margin: 5px 4px;
    padding: 5px;
    cursor: pointer;
    box-shadow: 3px 2px 5px #444;
    transition: 300ms ease;
}

.image-thumbnail img {
    display: block;
    width: 360px;
    height: 200px;
}

.gallery:hover .image-thumbnail:not(:hover) {
    filter: blur(2px);
    opacity: 0.9;
    transform: scale(0.9);
}

.light-box {
    position: fixed;
    top: 0;
    left: 0;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(72, 73, 74, 0.7);
    transition: 500ms ease;
    overflow: hidden;
}

.light-box:target {
    visibility: visible;
    opacity: 1;
}

.edges {
    border-radius: 15px;
    width: min-content;
    height: auto;
    background-color: #ccc;
    padding: 20px;
    margin: 30px auto;
    box-shadow: 0 0 30px #333;
}

.title {
    color: #222;
    text-align: center;
}

.inner-image {
    position: relative;
    width: 100%;
    height: 100%;
}

.inner-image img {
    max-width: 700px;
    max-height: 450px;
}

.close-btn,
.next-btn {
    float: right;
}
</style>